import './SidebarDesktopMenu.less';

import { officeUserRoles } from '@fyooga/codebook';
import { Layout } from 'antd';
import React from 'react';

import { APPLICATION_SUBDOMAIN } from '../../../constants';
import { useEmployeeCurrentHandledOfficeData } from '../../../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { useHasUserAccessByOfficeRole } from '../../../hooks/useHasUserAccessByOfficeRole';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_CLIENTS,
    SETTINGS_TAB_KEY_GENERAL,
    SETTINGS_TAB_KEY_ORDERS,
    SETTINGS_TAB_KEY_STATISTICS,
    SETTINGS_TAB_KEY_WORKSHOPS,
} from '../../../router/constants';
import { MenuItemProps, SIDEBAR_SETTINGS_TITLE, SidebarDesktopMenu } from './SidebarDesktopMenu';

const { Sider } = Layout;

export const Sidebar = ({ isSidebarVisible }: { isSidebarVisible: boolean }) => {
    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData();

    const currentHandledOfficeId = employeeCurrentHandledOfficeData?.officeId;

    const {
        hasAccess: canUserAccessSettingsForRoleManagerOrSuperadmin,
        loading: loadingCanUserAccessSettingsForRoleManagerOrSuperadmin,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.MANAGER, officeUserRoles.SUPERADMIN]);

    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.SUPERADMIN]);

    const domain = employeeCurrentHandledOfficeData?.domain;

    const rootSlug = `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}`;

    const settingsItemsStartPath = `${rootSlug}/settings`;

    const sidebarLinks: MenuItemProps[] = [
        // { title: 'sidebar.dashboard', icon: 'Dashboard2', linkTo: `/${APPLICATION_SUBDOMAIN}`, isAccessible: true },
        /*
        {
            title: 'sidebar.dashboard',
            icon: 'Dashboard2',
            linkTo: `/${APPLICATION_SUBDOMAIN}`,
            isAccessible:
                !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin &&
                canUserAccessSettingsForRoleManagerOrSuperadmin,
            className: '',
        },
         */
        {
            title: 'sidebar.calendar',
            icon: 'Calendar1',
            linkTo: `${rootSlug}`,
            isAccessible: true,
            className: 'reactour-default-calendar-item',
        },
        {
            title: 'sidebar.workshops',
            icon: 'GraduationCap',
            linkTo: `${rootSlug}/${SETTINGS_TAB_KEY_WORKSHOPS}`,
            isAccessible:
                !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin &&
                canUserAccessSettingsForRoleManagerOrSuperadmin,
            className: '',
        },
        {
            title: 'sidebar.clients',
            icon: 'Clients',
            linkTo: `${rootSlug}/${SETTINGS_TAB_KEY_CLIENTS}`,
            isAccessible:
                !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin &&
                canUserAccessSettingsForRoleManagerOrSuperadmin,
            className: '',
        },
        {
            title: 'sidebar.orders',
            icon: 'ShoppingCart',
            linkTo: `${rootSlug}/${SETTINGS_TAB_KEY_ORDERS}`,
            isAccessible:
                !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin &&
                canUserAccessSettingsForRoleManagerOrSuperadmin,
            className: '',
        },
        {
            title: 'sidebar.statistics',
            icon: 'Statistics',
            linkTo: `${rootSlug}/${SETTINGS_TAB_KEY_STATISTICS}/revenues`,
            isAccessible: !loadingCanUserAccessSettingsForSuperadminRole && canUserAccessSettingsForSuperadminRole,
            className: '',
        },
        {
            title: SIDEBAR_SETTINGS_TITLE,
            icon: 'SettingsGears',
            linkTo: `${settingsItemsStartPath}/${SETTINGS_TAB_KEY_GENERAL}`,
            isAccessible: !loadingCanUserAccessSettingsForSuperadminRole && canUserAccessSettingsForSuperadminRole,
            className: 'reactour-default-settings-item',
        },
    ];

    // > 1 because there is always one item which is visible (calendar icon) and we dont want to show only one icon
    isSidebarVisible = isSidebarVisible && sidebarLinks.filter(item => item.isAccessible).length > 0;

    return (
        <>
            {isSidebarVisible && (
                <Sider className="sidebar-left" width={72}>
                    {employeeCurrentHandledOfficeData && currentHandledOfficeId && (
                        <SidebarDesktopMenu
                            settingsItemsStartPath={settingsItemsStartPath}
                            sidebarLinks={sidebarLinks}
                        />
                    )}
                </Sider>
            )}
        </>
    );
};
