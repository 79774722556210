import { Tooltip } from 'antd';
import { addDays, parseISO } from 'date-fns';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import {
    UNLIMITED_ORDERS_CONSTANT,
    getMaxAllowedOrdersByTariff,
    useCheckTariffLimits,
} from '../../hooks/useCheckTariffLimits';
import { useFormatters } from '../../lib/formatters';
import { OfficeT } from '../../types/office';
import { MaxOrdersProgressCircle, getFormattedLimit } from './MaxOrdersProgressCircle';

interface AuthorizedHeaderTariffLimitsProps {
    office?: OfficeT;
}

export const AuthorizedHeaderTariffLimits = ({ office }: AuthorizedHeaderTariffLimitsProps) => {
    const { t } = useTranslation();
    const { data } = useCheckTariffLimits(office);
    const { DateFormatter } = useFormatters();

    if (!data) {
        return null;
    }

    const {
        totalOrdersCount,
        activeNotCanceledWorkshops,
        activeNotCanceledCourses,
        maxAllowedOrdersCount,
        organisationTariff,
        bookTariff,
    } = data;

    const { validFrom, validTo } = organisationTariff;
    const tariffName = bookTariff?.name;
    const isMonthlySubscription = bookTariff?.monthly ?? false;

    const remainingAllowedOrders = maxAllowedOrdersCount - totalOrdersCount;

    const ordersRemainingTranslation = t('plurals.order', {
        postProcess: 'interval',
        count: remainingAllowedOrders,
    });

    return (
        <>
            {tariffName && getMaxAllowedOrdersByTariff(tariffName) !== UNLIMITED_ORDERS_CONSTANT ? (
                <>
                    <div className="header-separator" />
                    <Tooltip
                        title={t('subscriptionNs:freeRemainingOrders', {
                            remainingOrders: remainingAllowedOrders,
                            validFrom: DateFormatter.format(parseISO(validFrom), {
                                day: 'numeric',
                                month: 'numeric',
                                year: 'numeric',
                            }),
                            validTo: isMonthlySubscription
                                ? DateFormatter.format(parseISO(validTo), {
                                      day: 'numeric',
                                      month: 'numeric',
                                      year: 'numeric',
                                  })
                                : DateFormatter.format(addDays(parseISO(validFrom), 30), {
                                      day: 'numeric',
                                      month: 'numeric',
                                      year: 'numeric',
                                  }),
                            classPlural: ordersRemainingTranslation,
                        })}
                    >
                        {!isMobile ? (
                            <div style={{ width: 150, display: 'flex', alignItems: 'center', paddingBottom: '3px' }}>
                                <MaxOrdersProgressCircle current={totalOrdersCount} max={maxAllowedOrdersCount} />
                            </div>
                        ) : (
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 'medium' }}>
                                <b>{getFormattedLimit(totalOrdersCount, maxAllowedOrdersCount, false)}</b>
                            </div>
                        )}
                    </Tooltip>
                </>
            ) : null}
        </>
    );
};
