import { useApolloClient, useReactiveVar } from '@apollo/client';
import { Dropdown, MenuProps } from 'antd';
import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import LecturerIcon from '../../components/LectureDetail/LecturerIcon';
import { Caret } from '../../components/ui/Caret';
import { config } from '../../config';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { AuthContext } from '../../context/AuthProvider';
import history from '../../history';
import { userIdVar } from '../../lib/apollo/cache';
import { APP_TAB_KEY_ORGANISATIONS, USER_TAB_KEY_PERSONAL_INFO, commonRoutes } from '../../router/constants';
import { makeUserLogout } from '../../services/auth/authProvider';
import { isVisibleSystemSuperadminMenu } from '../../util/admin';

export const UserMenuWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const LOGOUT_KEY = `userMenuKey-logout`;

export const UserProfileDropdown = () => {
    const { t } = useTranslation();
    const client = useApolloClient();
    const { userData } = useContext(AuthContext);
    const [isActive, setIsActive] = useState(false);
    const userId = useReactiveVar(userIdVar);

    const userMenuItems = useMemo(() => {
        let items = {
            [`userMenuKey-dashboard`]: {
                key: 'userMenuKey-dashboard',
                label: `${t('sidebar.dashboard')} (${t('headerMenu.userOrdersMenu')})`,
                linkTo: `/${APPLICATION_SUBDOMAIN}`,
            },
            [`userMenuKey-settings`]: {
                key: 'userMenuKey-settings',
                label: t('headerMenu.profile'),
                linkTo: `/${APPLICATION_SUBDOMAIN}/${commonRoutes.USER}/${userId}/settings/${USER_TAB_KEY_PERSONAL_INFO}`,
            },
            [`userMenuKey-vouchers`]: {
                key: 'userMenuKey-vouchers',
                label: t('headerMenu.vouchers'),
                linkTo: `/${APPLICATION_SUBDOMAIN}/${commonRoutes.USER}/${userId}/vouchers/`,
            },
            ...(userData &&
                isVisibleSystemSuperadminMenu(userData.email) && {
                    [`userMenuKey-admin`]: {
                        key: 'userMenuKey-admin',
                        label: t('headerMenu.admin'),
                        linkTo: `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_ORGANISATIONS}`,
                    },
                }),
            divider: {
                key: 'divider',
            },
            LOGOUT_KEY: {
                key: 'userMenuKey-logout',
                label: t('headerMenu.logout'),
            },
        };

        return items;
    }, [userData]);

    const userMenu = useMemo(() => {
        const itemsSize = Object.keys(userMenuItems).length;

        if (itemsSize > 0) {
            const itemsGroup: MenuProps['items'] = [];
            for (let itemKey in userMenuItems) {
                // @ts-ignore
                const item = userMenuItems[itemKey];
                const { key, label } = item;
                if (key === 'divider') {
                    itemsGroup.push({
                        type: 'divider',
                    });
                } else {
                    itemsGroup.push({
                        key,
                        label,
                    });
                }
            }
            return itemsGroup;
        }
        return [];
    }, [userMenuItems]);

    const logout = async () => {
        await makeUserLogout(client);
        history.push(`/${commonRoutes.LOGIN}`);
    };

    if (userData) {
        return (
            <>
                <UserMenuWrapper>
                    <Dropdown
                        trigger={['click']}
                        placement="bottomRight"
                        onOpenChange={visible => setIsActive(visible)}
                        menu={{
                            items: userMenu,
                            onClick: event => {
                                setIsActive(false);
                                const { key } = event;
                                // @ts-ignore
                                const itemData = userMenuItems[key];
                                if (itemData?.linkTo) {
                                    history.push(itemData.linkTo);
                                } else if (key === LOGOUT_KEY) {
                                    logout();
                                }
                            },
                            selectable: true,
                        }}
                        overlayStyle={{
                            minWidth: '200px',
                        }}
                    >
                        <div className="userMenu-profile">
                            <LecturerIcon
                                name={userData?.name}
                                image={userData?.image}
                                withName={false}
                                size={28}
                                withOpenModal={false}
                            />
                            <span className="userProfile__name">{userData?.name}</span>
                            <Caret isActive={isActive} />
                        </div>
                    </Dropdown>
                </UserMenuWrapper>
            </>
        );
    }
    return null;
};
